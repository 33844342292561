<template>
  <div class="special-package open">
    <div class="overlay" @click="this.$emit('hide')"></div>
    <div class="card-package">
      <div class="row">
        <div class="col-12">
          <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M27 52V29.5189M47.0401 29.5189V39.1858C47.0411 40.1286 46.7822 41.0536 46.2919 41.8597C45.8015 42.6659 45.0985 43.3221 44.2595 43.7569L29.2295 51.4505C28.5408 51.8073 27.7761 51.9936 27 51.9936C26.2239 51.9936 25.4592 51.8073 24.7705 51.4505L9.74048 43.7569C8.90151 43.3221 8.19847 42.6659 7.70811 41.8597C7.21776 41.0536 6.95894 40.1286 6.95992 39.1858V29.5189M34.9409 2.56663C35.5648 2.21878 36.2677 2.03614 36.9825 2.03614C37.6973 2.03614 38.4001 2.21878 39.024 2.56663L49.5451 8.46167C50.2902 8.88182 50.9101 9.4917 51.3414 10.229C51.7727 10.9663 52 11.8046 52 12.6581C52 13.5117 51.7727 14.35 51.3414 15.0873C50.9101 15.8246 50.2902 16.4344 49.5451 16.8546L19.0341 33.9902C18.4083 34.3461 17.7004 34.5333 16.98 34.5333C16.2596 34.5333 15.5516 34.3461 14.9259 33.9902L4.45491 28.0951C3.70979 27.675 3.0899 27.0651 2.65858 26.3278C2.22727 25.5905 2 24.7522 2 23.8987C2 23.0451 2.22727 22.2068 2.65858 21.4695C3.0899 20.7322 3.70979 20.1224 4.45491 19.7022L34.9409 2.56663ZM49.5451 28.0951C50.2902 27.675 50.9101 27.0651 51.3414 26.3278C51.7727 25.5905 52 24.7522 52 23.8987C52 23.0451 51.7727 22.2068 51.3414 21.4695C50.9101 20.7322 50.2902 20.1224 49.5451 19.7022L19.0591 2.54165C18.4376 2.18672 17.7338 2 17.0175 2C16.3013 2 15.5975 2.18672 14.976 2.54165L4.45491 8.46167C3.70979 8.88182 3.0899 9.4917 2.65858 10.229C2.22727 10.9663 2 11.8046 2 12.6581C2 13.5117 2.22727 14.35 2.65858 15.0873C3.0899 15.8246 3.70979 16.4344 4.45491 16.8546L34.9659 33.9902C35.5871 34.3461 36.2911 34.5333 37.0075 34.5333C37.724 34.5333 38.4279 34.3461 39.0491 33.9902L49.5451 28.0951Z"
              stroke="#225476" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg class="close" @click="this.$emit('hide')" width="12" height="12" viewBox="0 0 12 12"
               fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.6561 1.33776L6.99387 6L11.6561 10.6622C11.788 10.7941 11.8621 10.973 11.8621 11.1595C11.8621 11.3461 11.788 11.525 11.6561 11.6569C11.5242 11.7887 11.3453 11.8628 11.1588 11.8628C10.9723 11.8628 10.7934 11.7887 10.6615 11.6569L5.99926 6.99461L1.33701 11.6569C1.20512 11.7887 1.02623 11.8628 0.839708 11.8628C0.653182 11.8628 0.474295 11.7887 0.342401 11.6569C0.210507 11.525 0.136411 11.3461 0.136411 11.1595C0.136411 10.973 0.210508 10.7941 0.342401 10.6622L5.00464 6L0.342402 1.33776C0.210508 1.20586 0.136411 1.02698 0.13641 0.840451C0.13641 0.653925 0.210507 0.47504 0.342401 0.343146C0.474295 0.211252 0.653181 0.137155 0.839707 0.137154C1.02623 0.137155 1.20512 0.211252 1.33701 0.343145L5.99926 5.00539L10.6615 0.343146C10.7934 0.211252 10.9723 0.137155 11.1588 0.137155C11.3453 0.137155 11.5242 0.211252 11.6561 0.343146C11.788 0.47504 11.8621 0.653925 11.8621 0.840452C11.8621 1.02698 11.788 1.20586 11.6561 1.33776Z"
              fill="#0a3041"/>
          </svg>
        </div>
        <div class="col-12">
          <h2 class="title">{{ this.$i18n.locale == `ar` ? `صمم باقتك!` : `Design your package!` }}</h2>
        </div>
      </div>
      <Form @submit="onSubmit" :validation-schema="schema">
      <div class="row question">

        <div class="col-12">
          <div class="row" v-if="index == 1">

            <div class="col-md-6">
              <h3> {{
                  this.$i18n.locale == `ar` ? `قم باضافة الخدمات المطلوبة` : `Add the required
                                services`
                }}</h3>
            </div>
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
              <select name="mainCatgId" @change="mainCatgId">
                <option :value="item.id" v-for="item in MainCateg" :key="item.id">{{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <select name="SubCategId" @change="SubCategId">
                <option :value="item.id" v-for="item in SubCateg" :key="item.id">{{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-md-12 mt-3">
              <Field name="Service" v-slot="{ field }">
                <multiselect :multiple="true" :searchable="false" v-model="value" v-bind="field"
                             :options="Services"
                             :placeholder="this.$i18n.locale == 'ar' ? 'اختر الخدمات' : 'Choose the Services'"
                             label="title" track-by="title" selectLabel=""
                             :selectedLabel="this.$i18n.locale == 'ar' ? 'محددة' : 'Selected'"
                             :deselectLabel="this.$i18n.locale == 'ar' ? 'اضغط للإزالة' : 'Press enter to remove'">
                </multiselect>
              </Field>
              <ErrorMessage name="Service" class="error-message"/>
            </div>
            <div class="col-md-6 mt-3">
              <h3> {{
                  this.$i18n.locale == `ar` ? `اختر عدد الاستشارات` : `Choose the number of
                                consultations`
                }}</h3>
            </div>
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
              <Field type="number" name="consultations_number" class="id_number"
                     v-model="FDATA.consultations_number"/>
              <ErrorMessage name="consultations_number" class="error-message"/>
            </div>
          </div>
        </div>
      </div>
        <div class="row bottom">
          <div class="col-6 d-flex justify-content-start">
            <button class="delete-services" @click="this.$emit('hide')">
              {{ this.$i18n.locale == `ar` ? `إلغاء الطلب` : `Cancel Order` }}
            </button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button type="submit" class="next-step">
              {{ $t('Submit Order') }}
            </button>
          </div>
        </div>
    </Form>
  </div>
  </div>
</template>
<script>

import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { configure } from 'vee-validate'
import Multiselect from 'vue-multiselect'
import Cookie from 'cookie-universal'

const cookie = Cookie()
export default {
  data () {
    return {
      index: 1,
      MainCateg: [],
      SubCateg: [],
      Services: [],
      value: '',
      FDATA: {
        company_id: cookie.get('Companydata').id,
        consultations_number: 0,
        services: []
      }
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup () {
    configure({
      validateOnInput: true,
    })

    const schema =
      yup.object({
        Service: yup.array()
          .required(cookie.get('lang') === 'ar' ? 'اختيار الخدمة مطلوب' : 'Services required'),
        consultations_number: yup.string()
          .test(
            'number',
            (field) => {
              return cookie.get('lang') == 'ar' ? 'يجب ألا يتجاوز 3 خانات' : 'It must not exceed 3 characters'
            },
            (value) => value.toString().length < 4,)
          .test(
            'number',
            (field) => {
              return cookie.get('lang') == 'ar' ? 'يجب إدخال رقم اكبر من 0' : 'You must enter a number greater than 0'
            },
            (value) => value > 0,)
          .test(
            'number',
            (field) => {
              return cookie.get('lang') == 'ar' ? 'يجب إدخال رقم اصغر من 100' : 'You must enter a number smaller than 100'
            },
            (value) => value < 101,)
          .required(cookie.get('lang') === 'ar' ? 'عدد الاستشارات مطلوب' : 'Number of Consultations required')
          .test('is-number', (value) => {
            return typeof value === 'string' && !isNaN(value)
          }, (field) => {
            return cookie.get('lang') === 'ar' ? 'يجب إدخال رقم' : 'must enter a number'
          }),
      })

    return {
      schema,
    }
  },
  methods: {

    onSubmit (values) {
      console.log('oy')
      for (var i = 0; i < this.value.length; i++) {
        this.FDATA.services.push(this.value[i].id)
      }
      axios
        .post(`/company/dashboard/packages`, this.FDATA,
          {
            headers: { 'Authorization': `Bearer ${cookie.get('CompanyToken')}` }
          })
        .then((res) => {
          if (res.status == 200) {
            this.$emit('hide')
            notify({
              type: 'success',
              text: this.$i18n.locale == 'ar' ? 'تم ارسال الطلب بنجاح' : 'Send Employee Invitation Success',
            })
            this.$router.push(`/packages/order/${res.data.data.id}`)
          }
        })
        .catch(function (error) {
          console.log('Error: ', error)
          notify({
            type: 'error',
            text: error?.response?.data?.message,
          })
        })
    },
    mainCatgId (event) {
      this.$store.dispatch('SubCatgsServices', event.target.value).then(r => {
        this.SubCateg = this.$store.state.SubCategSerives
        this.$store.dispatch('ServicesCard', this.SubCateg[0].id).then(r => {
          this.Services = this.$store.state.ServicesCard
        })

      })
    },

    SubCategId (event) {
      this.$store.dispatch('ServicesCard', event.target.value).then(r => {
        this.Services = this.$store.state.ServicesCard
      })
    },

    handelNextStep () {
      this.index += 1
    }
  },
  mounted () {
    this.$store.dispatch('MainCatgsServices').then(r => {
      this.MainCateg = this.$store.state.MainCategSerives
      this.$store.dispatch('SubCatgsServices', this.MainCateg[0].id).then(r => {
        this.SubCateg = this.$store.state.SubCategSerives
        this.$store.dispatch('ServicesCard', this.SubCateg[0].id).then(r => {
          this.Services = this.$store.state.ServicesCard
        })
      })
    })
  }

}
</script>
